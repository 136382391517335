
<template>
	<div id ="helpcentermodalmodal" class = "pagefixedwidth">

		<b-modal size ="l-x" @hidden= "openguidemodal = false" v-model="openguidemodal" >

			<template v-slot:modal-header="{cancel}">
				<h3 class="modal-title">First time here?</h3>
				<a class = "close-modal dialog-close-button mr-2">
					<i class="fal fa-times fa-lg fa-lg " @click="openguidemodal=false" ></i>
				</a>
			</template>
			<p>
				Would you like to read the 'Getting started with Flowe' guide? The guide will open in a new browser tab or window.
			</p>
			<template v-slot:modal-footer="{ ok,cancel }">
				<b-button @click="openguidemodal=false">Cancel</b-button>      
				<a class="btn-primary btn" href = "https://goflowe.atlassian.net/servicedesk/customer/portal/1/article/725057549" @click="openguidemodal=false">Open guide</a>               
			</template>
		</b-modal>

		

		<TopBar  simple  hidedropdown>
			<template v-slot:title>
				Help
			</template>
			<template v-slot:icon>
				<!-- <i class="fal fa-border-all align-self-center "></i> -->
			</template>
		</TopBar>
		<div class = "main-wrapper" >
			
			<div class = "help-gruppe pt-0">
				<div class= "help-gruppe-title">
					<h2 class = "title my-auto">
						<span class ="title-icon text-center d-inline-block">
							<i class="far  fa-book NO-c"></i>
						</span>
					</h2>
					<div class = "ml-4 my-auto">
						<h2 class="text-center"> Using Flowe </h2>
					</div>
				</div>
				
				<div v-if="showdetailpage.body">
					
					<a href="#" @click="showdetail()">Back</a>

					<h4>{{ showpage.title }} - {{ showdetailpage.title }}</h4>
					<div v-html="showdetailpage.body" ></div>
				</div>
				

				<div v-if="!showdetailpage.body">

				<p> Use the links below to access the guides in our Help Center:</p>

				<ul class="help-list m-0">
					<li>
						<div class="message-center wrapper mt-4"> <pre v-if="1==2">{{ pagescontent }}</pre>


							<div v-for="(page, key) in pagescontent" :key="key">
								<h4>{{ page.title }}</h4>
								<div class="sub" v-for="(subpage, key2) in page.contents" :key="key2">
									<a :href="'#'+subpage.pageid" @click="showdetail(page, subpage)">{{subpage.title}}</a>
								</div> 

							</div>

						</div>
					</li> 
				</ul>

				</div>
			</div>
			<div class="help-gruppe">
				<div class= "help-gruppe-title">
					<h2 class = "title my-auto">
						<span class ="title-icon text-center d-inline-block">
							<i class="fas fa-question NO-c"></i>
						</span>
					</h2>
					<div class = "ml-4">
						<h2 class="text-center mb-1">Contact Support</h2>
						<h3 class="text-center mb-0">We're here to help!</h3>
					</div>
				</div>
				<a target="_blank" href="https://help.flowe.io" class="card-block clearfix">
				 <div class = "card">
				 	<div class = "card-body">
				 		<div class ="d-flex flex-columen">
				 			<div class = "text-center my-auto w-100">
				 				<h1 class ="text-center"><i class="far fa-external-link-alt"></i></h1>
				 				<span nowrap>Help Center</span>
				 			</div>
				 			<div>
				 				Need additional help? Found a bug? Or a feature you would like us to add? Contact us through our Help Center (opens in a new browser tab). 
				 			</div>
				 		</div>
				 	</div>

				 </div>
				</a>
			</div>
			<div class="help-gruppe" style = "border: none" id="aboutpanel">
				<div class= "help-gruppe-title">
					<h2 class = "title my-auto">
						<img src="@/static/img/Logomark/FloweLogomark_BlackGold_M.png" alt="O" style ="width: 60px; height: 60px;" class = "mx-auto">
					</h2>
					<div class = "ml-4 my-auto">
						<h2 class="text-center mb-1">About Flowe</h2>
						<p class="mb-0">Flowe 3.6.215.1</p>
					</div>
				</div>
			<p class ="">Flowe is designed to work with the latest browsers. For the best experience please use one of the following browsers:</p>
			<h3 class="text-center" >Desktop:</h3>

			<p class="row justify-content-center"> 

				<a href="https://www.google.com/chrome/" target="_blank" class="col-3 d-flex flex-column">
					<img src="@/static/brands/chrome.png" style ="width: 45px; height: 45px;" alt="Chrome" class="bbrand mx-auto ">
					<div class ="mx-auto">Chrome</div>
				</a>

				<a href="https://www.microsoft.com/edge" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/edge.png" style ="width: 45px; height: 45px;" alt="Edge" class = "mx-auto">
				<div class ="mx-auto">Edge</div>
			</a>

				<a href="https://www.mozilla.org/" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/firefox.png" style ="width: 45px; height: 45px;" alt="Firefox"  class = "mx-auto">
					<div class ="mx-auto">Firefox</div>
				</a>
				
				<a href="https://support.apple.com/downloads/safari" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/safari.png" style ="width: 45px; height: 45px;" alt="Safari" class = "mx-auto">
					<div class ="mx-auto">Safari</div>
					</a>

			</p>

			<h3 class="text-center">Mobile: </h3>

			<p class="row justify-content-center"> 
				<a href="https://play.google.com/store/apps/details/Google_Chrome_Fast_Secure?id=com.android.chrome&hl=en" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/chrome.png" style ="width: 45px; height: 45px;" alt="Chrome" class = "mx-auto">
					<div class ="mx-auto">Chrome</div>
				</a>
					<a href="https://www.microsoft.com/edge" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/edge.png" style ="width: 45px; height: 45px;" alt="Edge" class = "mx-auto">
				<div class ="mx-auto">Edge</div>
				</a>

				<a href="https://www.mozilla.org/" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/firefox.png" style ="width: 45px; height: 45px;" alt="Firefox"  class = "mx-auto">
					<div class ="mx-auto">Firefox</div>
				</a>
				

				<a href="https://support.apple.com/downloads/safari" target="_blank" class="col-3 d-flex flex-column"><img src="@/static/brands/safari.png" style ="width: 45px; height: 45px;" alt="Safari" class = "mx-auto">
					<div class ="mx-auto">Safari</div>
				</a>

				<a href="https://www.samsung.com/dk/apps/samsung-internet/" target="_blank" class="col-3 mt-3 mr-auto d-flex flex-column"><img src="@/static/brands/samsung-internet.png" style ="width: 45px; height: 45px;" alt="Safari" class = "mx-auto">
					<div class ="mx-auto ">Samsung</div>
				</a>

			</p>
			
			
			<p class="mt-4">The use of this product is subject to our <a class="text-primary" target="_blank" href="https://flowe.io/termsofservice">Terms of Service</a> and <a target="_blank" class="text-primary" href="https://flowe.io/privacy">Privacy Policy</a>.</p>
			<p class="text-center" >Copyright Ⓒ Flowe</p>
			</div>
		</div>
	</div>

</template>

<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
.card:hover{
	box-shadow: 0 14px 26px -12px rgba(73, 96, 146, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(73, 96, 146, 0.2) !important;
}
a:hover{
	font-weight: $fw-bold;
	&.text-primary{
		font-weight: 400;
	}
}

.help-gruppe{
	padding:3rem 0;
	border-bottom: 2px solid $N200;
}

.help-gruppe-title{
	margin-bottom: 10px;
	display:flex;
	.title-icon{
		width:60px;
		height:60px;
		 line-height: 40px;
		background-color:$B500;
		border-radius:100%;
		padding:10px
	}
	
}


a.d-flex{
	cursor: pointer;
}
a.card-block:hover, a.d-flex:hover{
	text-decoration: none;
	background-color:#ffffff;
	color: $B500 !important;
}

.help-list{
	padding: 0px;

	li {

		list-style: none;

	}
	li .sub {

		padding: 5px 20px 5px;

	}

	> li {
		height:auto;
		> a{
			padding: 10px 0;
			font-weight: $fw-bold !important;

		}

	}

	li a {
		display: block;

	}

}

.bbrand{
	height: 45px;
}

</style>
<script type="text/javascript"></script>
<script>
	import {TopBar} from '@/components/topbar'
	import {bus} from '@/main'

	import Dashboardapi from '@/services/api/dashboard'

	export default{
		name:'helpContent',
		data(){
			return{
				showaboutFlowe:false,
				openguidemodal:false,
				pages:[],
				parentids:[],				
				sections:[],
				pagescontent:{},
				showpage:{},
				showdetailpage:{},
			}

		},
		components:{
			TopBar
		},

		computed:{
			showhints(){
				return this.$store.state.showhints
			}
		},

		mounted(){

			let self = this

			$('#preloader').addClass('hide')
			if(this.$route.query.page == 'about'){
				this.showaboutFlowe = true
				var aboutpanel = document.getElementById('aboutpanel');
				aboutpanel.scrollIntoView();
			}
			this.$nextTick(function () {
				document.title ='Help | Flowe'
			})

			Dashboardapi.Loadpages().then( function( response ){


				self.pages = response.data.pages

				self.renderpages()
			})
		},


		methods:{

			showdetail( page, detailpage ){

				this.showpage = {}	
				this.showdetailpage = {}


				if( page && detailpage ){
					this.showpage = page
					this.showdetailpage = detailpage
				}

				
				
			},
			showhint(){
				this.$store.commit('SET_SHOWHINTS',!this.showhints)
			},

			renderpages(){

				let parentids = []

				let pagescontent = {}

				// first level

				for( var i in this.pages ){

					let page = this.pages[i]

					if( page.parentid != "null" && parentids.indexOf( page.parentid ) == -1 ){
						parentids.push( page.parentid )

						pagescontent[ page.parentid ] = { "contents":[] }

					}
				}

				for( var i in this.pages ){

					let page = this.pages[i]

					if( pagescontent[ page.parentid ] ){
						pagescontent[ page.parentid ].contents.push( { "parentid":page.parentid, "pageid":page.pageid, "title":page.title, "contentids":[], "contents":[] }	 )
					}
					
				}

				// second level

				let root = pagescontent["720863239"].contents
			

				for( var o in this.pages ){

					for( var i in root ){

						let page = this.pages[o]

						if( root[i].pageid == page.parentid ){

								root[i].contentids.push( page.pageid )

						}

					}
					
				}
				

				pagescontent = root				

				this.pagescontent = pagescontent

			}
		},
	}
</script>